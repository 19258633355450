import _decode from "./decode";
import _encode from "./encode";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.decodeXMLStrict = exports.decodeHTML5Strict = exports.decodeHTML4Strict = exports.decodeHTML5 = exports.decodeHTML4 = exports.decodeHTMLStrict = exports.decodeHTML = exports.decodeXML = exports.encodeHTML5 = exports.encodeHTML4 = exports.escapeUTF8 = exports.escape = exports.encodeNonAsciiHTML = exports.encodeHTML = exports.encodeXML = exports.encode = exports.decodeStrict = exports.decode = void 0;
var decode_1 = _decode;
var encode_1 = _encode;
/**
 * Decodes a string with entities.
 *
 * @param data String to decode.
 * @param level Optional level to decode at. 0 = XML, 1 = HTML. Default is 0.
 * @deprecated Use `decodeXML` or `decodeHTML` directly.
 */

function decode(data, level) {
  return (!level || level <= 0 ? decode_1.decodeXML : decode_1.decodeHTML)(data);
}

exports.decode = decode;
/**
 * Decodes a string with entities. Does not allow missing trailing semicolons for entities.
 *
 * @param data String to decode.
 * @param level Optional level to decode at. 0 = XML, 1 = HTML. Default is 0.
 * @deprecated Use `decodeHTMLStrict` or `decodeXML` directly.
 */

function decodeStrict(data, level) {
  return (!level || level <= 0 ? decode_1.decodeXML : decode_1.decodeHTMLStrict)(data);
}

exports.decodeStrict = decodeStrict;
/**
 * Encodes a string with entities.
 *
 * @param data String to encode.
 * @param level Optional level to encode at. 0 = XML, 1 = HTML. Default is 0.
 * @deprecated Use `encodeHTML`, `encodeXML` or `encodeNonAsciiHTML` directly.
 */

function encode(data, level) {
  return (!level || level <= 0 ? encode_1.encodeXML : encode_1.encodeHTML)(data);
}

exports.encode = encode;
var encode_2 = _encode;
Object.defineProperty(exports, "encodeXML", {
  enumerable: true,
  get: function () {
    return encode_2.encodeXML;
  }
});
Object.defineProperty(exports, "encodeHTML", {
  enumerable: true,
  get: function () {
    return encode_2.encodeHTML;
  }
});
Object.defineProperty(exports, "encodeNonAsciiHTML", {
  enumerable: true,
  get: function () {
    return encode_2.encodeNonAsciiHTML;
  }
});
Object.defineProperty(exports, "escape", {
  enumerable: true,
  get: function () {
    return encode_2.escape;
  }
});
Object.defineProperty(exports, "escapeUTF8", {
  enumerable: true,
  get: function () {
    return encode_2.escapeUTF8;
  }
}); // Legacy aliases (deprecated)

Object.defineProperty(exports, "encodeHTML4", {
  enumerable: true,
  get: function () {
    return encode_2.encodeHTML;
  }
});
Object.defineProperty(exports, "encodeHTML5", {
  enumerable: true,
  get: function () {
    return encode_2.encodeHTML;
  }
});
var decode_2 = _decode;
Object.defineProperty(exports, "decodeXML", {
  enumerable: true,
  get: function () {
    return decode_2.decodeXML;
  }
});
Object.defineProperty(exports, "decodeHTML", {
  enumerable: true,
  get: function () {
    return decode_2.decodeHTML;
  }
});
Object.defineProperty(exports, "decodeHTMLStrict", {
  enumerable: true,
  get: function () {
    return decode_2.decodeHTMLStrict;
  }
}); // Legacy aliases (deprecated)

Object.defineProperty(exports, "decodeHTML4", {
  enumerable: true,
  get: function () {
    return decode_2.decodeHTML;
  }
});
Object.defineProperty(exports, "decodeHTML5", {
  enumerable: true,
  get: function () {
    return decode_2.decodeHTML;
  }
});
Object.defineProperty(exports, "decodeHTML4Strict", {
  enumerable: true,
  get: function () {
    return decode_2.decodeHTMLStrict;
  }
});
Object.defineProperty(exports, "decodeHTML5Strict", {
  enumerable: true,
  get: function () {
    return decode_2.decodeHTMLStrict;
  }
});
Object.defineProperty(exports, "decodeXMLStrict", {
  enumerable: true,
  get: function () {
    return decode_2.decodeXML;
  }
});
export default exports;
export const __esModule = exports.__esModule,
      decodeXMLStrict = exports.decodeXMLStrict,
      decodeHTML5Strict = exports.decodeHTML5Strict,
      decodeHTML4Strict = exports.decodeHTML4Strict,
      decodeHTML5 = exports.decodeHTML5,
      decodeHTML4 = exports.decodeHTML4,
      decodeHTMLStrict = exports.decodeHTMLStrict,
      decodeHTML = exports.decodeHTML,
      decodeXML = exports.decodeXML,
      encodeHTML5 = exports.encodeHTML5,
      encodeHTML4 = exports.encodeHTML4,
      escapeUTF8 = exports.escapeUTF8,
      encodeNonAsciiHTML = exports.encodeNonAsciiHTML,
      encodeHTML = exports.encodeHTML,
      encodeXML = exports.encodeXML;
const _escape = exports.escape,
      _encode2 = exports.encode,
      _decodeStrict = exports.decodeStrict,
      _decode2 = exports.decode;
export { _escape as escape, _encode2 as encode, _decodeStrict as decodeStrict, _decode2 as decode };